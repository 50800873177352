import jwt_decode from "jwt-decode";

export default {
    getLoader(state) {
        return state.loader;
    },
    getEmail() {
        let e = localStorage.getItem("admin.csp");
        if (e && e.length) {
            let decoded = jwt_decode(e)
            return decoded.email;
        }
        return false;
    },
}