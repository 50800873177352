import axios from "axios";
import api from '@/core/api';
import Vue from "vue";
import PredictionsModel from "../models/predictions.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.predictions + `?PageNumber=1&PageSize=10000`)
            .then((res) => {
                let data = res.data.predictions;
                commit(
                    "setItems",
                    data.map(i => {
                        return new PredictionsModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.predictions, payload)
            .then((res) => {
                Vue.$toast.success(res.data);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.predictions + `/${payload.id}`, payload.data)
            .then((response) => {
                const res = response.data;
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    deleteItem({commit}, id) {
        return axios
            .delete(api.predictions + `/${id}`)
            .then((response) => {
                const res = response.data;
                commit("deleteItem", id);
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.predictions + `/${id}`)
            .then((response) => {
                const res = response.data.prediction;
                return new PredictionsModel(res);
            })
            .catch(() => {
                return false;
            });
    },

    getMatches({commit}) {
        return axios
            .get(api.matches)
            .then((res) => {
                let data = res.data.matches;
                commit(
                    "setMatches",
                    data
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    approveResult({commit}, payload) {
        return axios
            .put(api.approveResult + `/${payload.id}`, payload.result)
            .then((response) => {
                const res = response.data;
                commit('approveResult', payload.id);
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    }
}
