export default class PredictionsModel {
    constructor(item) {
        this._setId(item);
        this._setFirstCompetitorName(item);
        this._setFirstCompetitorLogo(item);
        this._setFirstCompetitorOdd(item);
        this._setFirstCompetitorScore(item);
        this._setFirstCompetitorUrl(item);
        this._setFirstCompetitorPredictionScore(item);
        this._setSecondCompetitorName(item);
        this._setSecondCompetitorLogo(item);
        this._setSecondCompetitorOdd(item);
        this._setSecondCompetitorScore(item);
        this._setSecondCompetitorPredictionScore(item);
        this._setSecondCompetitorUrl(item);
        this._setGameId(item);
        this._setIsEnded(item);
        this._setResultText(item);
        this._setResultText_ru(item);
        this._setTypeText(item);
        this._setTypeText_ru(item);
        this._setStartDate(item);
        this._setPublishTime(item);
        this._setTournamentId(item);

        this._setTitle(item);
        this._setIframe(item);
        this._setTitle_ru(item);
        this._setDescription(item);
        this._setDescription_ru(item);
        this._setCategoryId(item);
        this._setMatchId(item);
        this._setKeyPoints(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }


    /**
     * set start date
     * @param startDate
     * @private
     */
    _setStartDate({startDate}) {
        let str1 = startDate.split('T');
        this.date = str1[0];
        this.time = str1[1];
    }

      /**
     * set start date
     * @param publishTime
     * @private
     */
      _setPublishTime({publishTime}) {
        let str1 = publishTime.split('T');
        this.publishDate = str1[0];
        this.publishTime = str1[1];
    }

    _setFirstCompetitorName({firstCompetitor}) {
        this.firstName = firstCompetitor.name;
    }

    _setFirstCompetitorLogo({firstCompetitor}) {
        if (!firstCompetitor.logoUrl.includes('http')) {
            this.firstLogo = 'https://' + firstCompetitor.logoUrl;
        } else {
            this.firstLogo = firstCompetitor.logoUrl;
        }
    }

    _setFirstCompetitorOdd({firstCompetitor}) {
        this.firstOdd = firstCompetitor.odd;
    }

    _setFirstCompetitorScore({firstCompetitor}) {
        this.firstScore = firstCompetitor.score;
    }
    _setFirstCompetitorPredictionScore({firstCompetitor}) {
        this.firstPredictionScore = firstCompetitor.predictionScore;
    }
    _setFirstCompetitorUrl({firstCompetitor}) {
        this.firstUrl = firstCompetitor.url;
    }

    _setSecondCompetitorName({secondCompetitor}) {
        this.secondName = secondCompetitor.name;
    }

    _setSecondCompetitorLogo({secondCompetitor}) {
        if (!secondCompetitor.logoUrl.includes('http')) {
            this.secondLogo = 'https://' + secondCompetitor.logoUrl;
        } else {
            this.secondLogo = secondCompetitor.logoUrl;
        }
    }

    _setSecondCompetitorOdd({secondCompetitor}) {
        this.secondOdd = secondCompetitor.odd;
    }

    _setSecondCompetitorScore({secondCompetitor}) {
        this.secondScore = secondCompetitor.score;
    }
    _setSecondCompetitorPredictionScore({secondCompetitor}) {
        this.secondPredictionScore = secondCompetitor.predictionScore;
    }
    _setSecondCompetitorUrl({secondCompetitor}) {
        this.secondUrl = secondCompetitor.url;
    }

    _setGameId({game}) {
        this.gameId = game.id
    }

    _setTournamentId({tournament}) {
        this.tournamentId = tournament.id
    }

    _setIsEnded({isEnded}) {
        this.isEnded = isEnded
    }

    _setResultText({predictionResultText}) {
        this.resultText = predictionResultText;
    }

    _setResultText_ru({predictionResultText_ru}) {
        this.resultText_ru = predictionResultText_ru;
    }

    _setTypeText({predictionTypeText}) {
        this.typeText = predictionTypeText;
    }

    _setTypeText_ru({predictionTypeText_ru}) {
        this.typeText_ru = predictionTypeText_ru;
    }


    //categoryId

    _setCategoryId({category}) {
        this.categoryId = category.id;
    }

    //match id

    _setMatchId({matchId}) {
        this.matchId = matchId;
    }

    //title and description
    _setIframe({iframe}) {
        this.iframe = iframe;
    }

    _setTitle({title}) {
        this.title = title;
    }
    _setTitle_ru({title_ru}) {
        this.title_ru = title_ru;
    }

    _setDescription({description}) {
        this.description = description;
    }
    _setDescription_ru({description_ru}) {
        this.description_ru = description_ru;
    }
    _setKeyPoints({keyPoints}) {
        this.keyPoints = keyPoints;
    }
}
