import axios from "axios";
import api from '@/core/api';
import Vue from "vue";
import BlogsModel from '../models/blogs.model';

export default {
    getItems({commit}) {
        return axios
            .get(api.blog)
            .then((res) => {
                let data = res.data.blogs;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new BlogsModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.blog, payload)
            .then((res) => {
                Vue.$toast.success(res.data);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    removeItem({commit}, id) {
        return axios
            .delete(api.blog + `/${id}`)
            .then((response) => {
                const res = response.data;
                commit("removeItem", id);
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.blog + `/${payload.id}`, payload.data)
            .then((response) => {
                const res = response.data;
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.blog + `/${id}`)
            .then((response) => {
                const res = new BlogsModel(response.data.blog);
                return res;
            })
            .catch(() => {
                return false;
            });
    },

    publishItem({commit}, id) {
        return axios
            .post(api.blogPublish + `/${id}`)
            .then((response) => {
                const res = response.data;
                commit('publishItem', id);
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    }
}
