import Vue from 'vue';
import SectionTitle from './section-title/section-title.cmp.vue';
import EmptyAlert from './empty-alert/empty-alert.cmp.vue';
import ImageInput from './image-input/image-input.cmp.vue';
import RemoveDialog from './remove-dialog/remove-dialog.cmp.vue';

Vue.component("section-title", SectionTitle);
Vue.component("empty-alert", EmptyAlert);
Vue.component("image-input", ImageInput);
Vue.component("remove-dialog", RemoveDialog);
