import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: "#0b447a",
                yellow: "#ffc800",
                secondary: "#ccc",
                accent: "#8c9eff",
                error: "#da1f26",
                red: "#b70000"
            },
        },
    },
});
