export default {
    setItems(state, payload) {
        state.items = payload;
    },
    removeItem(state, payload) {
        state.items = state.items.filter((x) => {
            return x.id != payload;
        });
    },
    setUpTypes(state, payload) {
        state.upTypes = payload;
    },
}
