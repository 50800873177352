import axios from "axios";
import api from '@/core/api';
import Vue from "vue";
import GamesModel from "../models/games.model";

export default {
    getItems({commit}) {
        return axios
            .get(api.game)
            .then((res) => {
                let data = res.data.games;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new GamesModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.game, payload)
            .then((res) => {
                Vue.$toast.success(res.data);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    removeItem({commit}, id) {
        return axios
            .delete(api.game + `/${id}`)
            .then((response) => {
                const res = response.data;
                commit("removeItem", id);
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.game + `/${payload.id}`, payload.data)
            .then((response) => {
                const res = response.data;
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.game + `/${id}`)
            .then((response) => {
                const res = new GamesModel(response.data.gameDto);
                return res;
            })
            .catch(() => {
                return false;
            });
    }
}
