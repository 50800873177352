import Games from "../views/games/games.cmp.vue";

export default [
    {
        path: '/games',
        name: 'games',
        component: Games,
        meta: {
            name: 'Games'
        }
    },
    {
        path: "/news",
        name: "news",
        component: () => import(/* webpackChunkName: "news" */ "../views/news/news.cmp.vue"),
        meta: {
            name: 'News'
        }
    },
    {
        path: "/predictions",
        name: "predictions",
        component: () => import(/* webpackChunkName: "predictions" */ "../views/predictions/predictions.cmp.vue"),
        meta: {
            name: 'Predictions'
        }
    },
    {
        path: "/advertisements",
        name: "advertisements",
        component: () => import(/* webpackChunkName: "advertisements" */ "../views/advertisements/advertisements.cmp.vue"),
        meta: {
            name: 'Advertisements'
        }

    },
    {
        path: "/blogs",
        name: "blogs",
        component: () => import(/* webpackChunkName: "blogs" */ "../views/blogs/blogs.cmp.vue"),
        meta: {
            name: 'Blogs'
        }
    },
    {
        path: "/bookmakers",
        name: "bookmakers",
        component: () => import(/* webpackChunkName: "bookmakers" */ "../views/bookmakers/bookmakers.cmp.vue"),
        meta: {
            name: 'Bookmakers'
        }
    },
    {
        path: "/teams",
        name: "teams",
        component: () => import(/* webpackChunkName: "teams" */ "../views/teams/teams.cmp.vue"),
        meta: {
            name: 'Teams'
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login/login.cmp.vue"),
        meta: {
            name: 'Login'
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import(/* webpackChunkName: "settings" */ "../views/settings/settings.cmp.vue"),
        meta: {
            name: 'Settings'
        }
    },
    {
        path: "/categories",
        name: "categories",
        component: () => import(/* webpackChunkName: "categories" */ "../views/categories/categories.cmp.vue"),
        meta: {
            name: 'Categories'
        }
    },
    {
        path: "/tournaments",
        name: "tournaments",
        component: () => import(/* webpackChunkName: "tournaments" */ "../views/tournaments/tournaments.cmp.vue"),
        meta: {
            name: 'Tournaments'
        }
    },

    //add
    {
        path: "/games/add",
        name: "game-add",
        component: () => import(/* webpackChunkName: "game-add" */ "../views/game-add/game-add.cmp.vue"),
        meta: {
            name: 'Add New Game'
        }
    },
    {
        path: "/blogs/add",
        name: "blog-add",
        component: () => import(/* webpackChunkName: "blog-add" */ "../views/blog-add/blog-add.cmp.vue"),
        meta: {
            name: 'Add New Blog'
        }
    },
    {
        path: "/news/add",
        name: "news-add",
        component: () => import(/* webpackChunkName: "news-add" */ "../views/news-add/news-add.cmp.vue"),
        meta: {
            name: 'Add New News'
        }
    },
    {
        path: "/categories/add",
        name: "category-add",
        component: () => import(/* webpackChunkName: "category-add" */ "../views/category-add/category-add.cmp.vue"),
        meta: {
            name: 'Add New Category'
        }
    },
    {
        path: "/bookmakers/add",
        name: "bookmaker-add",
        component: () => import(/* webpackChunkName: "bookmaker-add" */ "../views/bookmaker-add/bookmaker-add.cmp.vue"),
        meta: {
            name: 'Add New Bookmaker'
        }
    },
    {
        path: "/teams/add",
        name: "team-add",
        component: () => import(/* webpackChunkName: "team-add" */ "../views/team-add/team-add.cmp.vue"),
        meta: {
            name: 'Add New Team'
        }
    },
    {
        path: "/predictions/add",
        name: "prediction-add",
        component: () => import(/* webpackChunkName: "prediction-add" */ "../views/prediction-add/prediction-add.cmp.vue"),
        meta: {
            name: 'Add New Prediction'
        }
    },
    {
        path: "/tournaments/add",
        name: "tournament-add",
        component: () => import(/* webpackChunkName: "tournament-add" */ "../views/tournament-add/tournament-add.cmp.vue"),
        meta: {
            name: 'Add New Tournament'
        }
    },
    //edit
    {
        path: "/games/edit/:id",
        name: "game-edit",
        component: () => import(/* webpackChunkName: "game-edit" */ "../views/game-edit/game-edit.cmp.vue"),
        meta: {
            name: 'Edit Game'
        }
    },
    {
        path: "/blogs/edit/:id",
        name: "blog-edit",
        component: () => import(/* webpackChunkName: "blog-edit" */ "../views/blog-edit/blog-edit.cmp.vue"),
        meta: {
            name: 'Edit Blog'
        }
    },
    {
        path: "/news/edit/:id",
        name: "news-edit",
        component: () => import(/* webpackChunkName: "news-edit" */ "../views/news-edit/news-edit.cmp.vue"),
        meta: {
            name: 'Edit News'
        }
    },
    {
        path: "/categories/edit/:id",
        name: "category-edit",
        component: () => import(/* webpackChunkName: "category-edit" */ "../views/category-edit/category-edit.cmp.vue"),
        meta: {
            name: 'Edit Blog'
        }
    },
    {
        path: "/bookmakers/edit/:id",
        name: "bookmaker-edit",
        component: () => import(/* webpackChunkName: "bookmaker-edit" */ "../views/bookmaker-edit/bookmaker-edit.cmp.vue"),
        meta: {
            name: 'Edit Bookmakers'
        }
    },

    {
        path: "/teams/edit/:id",
        name: "team-edit",
        component: () => import(/* webpackChunkName: "team-edit" */ "../views/team-edit/team-edit.cmp.vue"),
        meta: {
            name: 'Edit Team'
        }
    },
    {
        path: "/predictions/edit/:id",
        name: "prediction-edit",
        component: () => import(/* webpackChunkName: "prediction-edit" */ "../views/prediction-edit/prediction-edit.cmp.vue"),
        meta: {
            name: 'Edit Prediction'
        }
    },
    {
        path: "/tournaments/edit/:id",
        name: "tournament-edit",
        component: () => import(/* webpackChunkName: "tournament-edit" */ "../views/tournament-edit/tournament-edit.cmp.vue"),
        meta: {
            name: 'Edit Tournament'
        }
    },

    {
        path: "*",
        name: "NotFound",
        redirect: {
            name: 'predictions'
        },
    },
];
