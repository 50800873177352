export default class BookmakersModel {
    constructor(item) {
        this._setId(item);
        this._setName(item);
        this._setNameRu(item);
        this._setOrder(item);
        this._setDescription(item);
        this._setDescriptionRu(item);
        this._setBettingOn(item);
        this._setBonus(item);
        this._setRating(item);
        this._setImage(item);
        this._setUrl(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }

    /**
     * set name ru
     * @param name_ru
     * @private
     */
    _setNameRu({name_ru}) {
        this.name_ru = name_ru;
    }

    /**
     * set name ru
     * @param order
     * @private
     */
    _setOrder({order}) {
        this.order = order;
    }


    /**
     * set image
     * @param photoUrl
     * @private
     */
    _setImage({photoUrl}) {
        this.image = photoUrl;
    }

    /**
     * set description
     * @param description
     * @private
     */
    _setDescription({description}) {
        this.description = description;
    }

    /**
     * set description ru
     * @param description_ru
     * @private
     */
    _setDescriptionRu({description_ru}) {
        this.description_ru = description_ru;
    }

    /**
     * set bettingOn
     * @param bettingOn
     * @private
     */
    _setBettingOn({bettingOn}) {
        this.bettingOn = bettingOn;
    }

    /**
     * set bonus
     * @param bonus
     * @private
     */
    _setBonus({bonus}) {
        this.bonus = bonus;
    }

    /**
     * set rating
     * @param rating
     * @private
     */
    _setRating({rating}) {
        this.rating = rating;
    }

     /**
     * set url
     * @param url
     * @private
     */
      _setUrl({url}) {
        this.url = url;
    }

}
