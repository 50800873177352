import TournamentsTypeModel from "./tournaments-type.model";
import CategoriesModel from "../../categories/models/categories.model";

export default class TournamentsModel {
    constructor(item) {
        this._setId(item);
        this._setName(item);
        this._setNameRu(item);
        this._setPrize(item);
        this._setPrizeRu(item);
        this._setStart(item);
        this._setEnd(item);
        this._setType(item);
        this._setImage(item);
        this._setCategory(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }

    /**
     * set name ru
     * @param name_ru
     * @private
     */
    _setNameRu({name_ru}) {
        this.name_ru = name_ru;
    }

    /**
     * set prize
     * @param prizePool
     * @private
     */
    _setPrize({prizePool}) {
        this.prize = prizePool;
    }

    /**
     * set prize ru
     * @param prizePool_ru
     * @private
     */
    _setPrizeRu({prizePool_ru}) {
        this.prize_ru = prizePool_ru;
    }

    /**
     * set start
     * @param startDate
     * @private
     */
    _setStart({startDate}) {
        this.start = startDate;
    }

    /**
     * set end
     * @param endDate
     * @private
     */
    _setEnd({endDate}) {
        this.end = endDate;
    }

    /**
     * set type
     * @param tournamentType
     * @private
     */
    _setType({tournamentType}) {
        this.type = new TournamentsTypeModel(tournamentType);
    }
    /**
     * set image
     * @param photoUrl
     * @private
     */
    _setImage({photoUrl}) {
        this.image = photoUrl;
    }

    /**
     * set category
     * @param item
     * @private
     */
    _setCategory({category}) {
        this.category = new CategoriesModel(category);
    }
}
