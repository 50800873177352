export default {
    data() {
        return {
            dialog: false,
            selected: null,
        };
    },
    methods: {
        removeDialog(e) {
            this.dialog = true;
            this.selected = e;
        },
        clearDialog() {
            this.dialog = false;
            this.selected = null;
        },
    },
};
