export default {
    props: {
        refresh: {
            type: Boolean,
        },
        propImage: {
            type: String | File,
        },
    },
    data() {
        return {
            image: null,
        };
    },
    methods: {
        clickInput() {
            this.$refs.photoInput.click();
        },
        changePhoto(e) {
            let file = e.target.files[0];
            if (file) {
                this.image = URL.createObjectURL(file);
            }
            this.$emit("new-image", file);
        },
    },
    computed: {
        finalImage() {

            if (this.propImage) {
                if (typeof this.propImage === "string") {
                    return this.propImage;
                } else {
                    return URL.createObjectURL(this.propImage);
                }
            }
            return this.image;
        }
    },
    watch: {
        refresh(val) {
            if (val === true) {
                this.image = null;
                this.$refs.photoInput.value = "";
            }
        },
    },
}
