import axios from "axios";
import api from '@/core/api';
import Vue from "vue";
import NewsModel from '../models/news.model';

export default {
    getItems({commit}) {
        return axios
            .get(api.news)
            .then((res) => {
                let data = res.data.news;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new NewsModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.news, payload)
            .then((res) => {
                Vue.$toast.success(res.data);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    removeItem({commit}, id) {
        return axios
            .delete(api.news + `/${id}`)
            .then((response) => {
                const res = response.data;
                commit("removeItem", id);
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.news + `/${payload.id}`, payload.data)
            .then((response) => {
                const res = response.data;
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.news + `/${id}`)
            .then((response) => {
                const res = new NewsModel(response.data.newsDto);
                return res;
            })
            .catch(() => {
                return false;
            });
    },

    publishItem({commit}, id) {
        return axios
            .post(api.newsPublish + `/${id}`)
            .then((response) => {
                const res = response.data;
                commit('publishItem', id);
                Vue.$toast.success(res);
                return true;
            })
            .catch(() => {
                return false;
            });
    }
}
