import router from '../router';

export default {
    setLoader(state, payload) {
        state.loader = payload;
    },
    logout() {
        localStorage.removeItem("admin.csp");
        router.push({name: "login"});
    }
}