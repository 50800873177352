export default class TournamentsTypeModel {
    constructor(item) {
        this._setId(item);
        this._setName(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }

}
